@font-face {
  font-family: 'Vibur';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/vibur/v13/DPEiYwmEzw0QRgTuJDsrdPXb.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

ul {
  list-style: none;
}

body {
  margin: 0px;
  font-family: 'Helvetica';
  color: white;
}

h1 {
  width: 100%;
  font-size: 42px;
  box-sizing: border-box;
  grid-column: 1;
  grid-row: 1;
}

h1, h2 {
  color: #fff;
  width: 100%;
  font-family: "Vibur", sans-serif;
  z-index: 103;
}

h3 {
  margin-top: 50px;
}

.innholdsboks {
  width: 100%;
  z-index: 100;
}

.app {
  height: 120vh;
  width: 100vw;
  text-align: center;
  background: linear-gradient(-12deg, rgb(187, 94, 182), rgb(255, 232, 205))
}

.overskriftboks {
  display: grid;
}


.innhold-wrapper {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  animation: 2s ease-in-out 0s 1 normal none running appear;
}

@keyframes appear {
  from { opacity: 0; }
  to   { opacity: 1; }
}


.polsewrapper {
    position:absolute ;
    width:100%;
    height:100%;
    top:-100px;
    left:0;
}

.polse {
  position:absolute ;
  display:block ;
}

.kolonne {
  display: inline-block;
  min-width: 150px;
}

.lagwrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 600px) {
  .app {
    height: 250vh;
  }

}

@keyframes fall{
    0%{
        opacity:1;
        top:-10%;
        transform:translateX (20px) rotate(0deg);
    }
    20%{
        transform:translateX (-20px) rotate(45deg);
    }
    40%{

        transform:translateX (-20px) rotate(90deg);
    }
    60%{
        
      transform:translateX (-20px) rotate(135deg); 
    }
    80%{
        opacity: 0.8;
        transform:translateX (-20px) rotate(180deg);
    }
    100%{
        opacity: 0;
        top:100%;
        transform:translateX (-20px) rotate(225deg);
    }
  }


  .neonText {
    color: #fff;
    opacity: 0;
    text-shadow:
      0 0 4px #fff,
      0 0 11px #fff,
      0 0 19px #fff,
      0 0 40px #f09,
      0 0 80px #f09,
      0 0 90px #f09,
      0 0 100px #f09,
      0 0 150px #f09;
    animation: pulsate 1s ease-in-out infinite alternate;
    -webkit-transform: translate3d(0,0,0);


  }



  @keyframes pulsate {
    100% {
        opacity: 1;
    
    }
    
    0% {
      opacity: 0;
  
  }
}
  
  
.pauseknapp {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  padding: 10px;
  border: none;
  transition: transform 0.1s ease-in-out;
  cursor: pointer;
  width: 45px;
  height: 45px;
  font-size: 32px;
}



.pauseknapp:hover {
  transform: scale(1.1);
}
.pauseknapp:active {
  transform: scale(0.95);
}